import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"

const NotFoundPage = ({ location }) => (
  <Layout headerType="solid" location={location}>
    <SeoComponent title="404: Not Found" />
    <div className="solid-header-page-spacer" />
    <div className="pb-10">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            <h1 className="mb-4">Sorry, this page does not exist.</h1>
            <p className="mb-0">
              Please check if the URL is entered correctly. If you think this is
              an error, please contact us.
            </p>
            <Link to="/" className="mt-4 btn btn-primary">
              Return Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
